<template>
  <div class="page">
    <div class="mint-page" @click="closePopup">
      <!-- Header and Navigation -->
      <div class="header">
        <div class="header-left-content">
          <h1>GONAD</h1>
        </div>
        <div class="header-right-content">
          <nav>
            <a
              href="https://twitter.com/Gonad_Monad"
              target="_blank"
              rel="noopener"
              >Twitter</a
            >
            <a href="https://gonadmonad.com" target="_blank" rel="noopener"
              >Discord</a
            >
            <a href="#FaqInfo" @click="scrollToFAQs">FAQs</a>
            <a href="#Roadmap" @click="scrollToRoadmap">Roadmap</a>
            <button @click.stop="toggleMetaMaskPopup" class="metamask-button">
              <img
                src="@/assets/metamask.png"
                alt="MetaMask Logo"
                class="metamask-logo"
              />
              <span v-if="account">{{ formattedAccount }}</span>
              <span v-else>Connect MetaMask</span>
            </button>
            <div v-if="showMetaMaskPopup" class="metamask-popup" @click.stop>
              <div class="metamask-popup-header">
                <img
                  src="@/assets/gonad_no_shadow.png"
                  alt="Gonad Logo"
                  class="metamask-popup-logo"
                />
                <button @click="closePopup" class="close-button">
                  &times;
                </button>
              </div>
              <div class="metamask-popup-content">
                <p v-if="correctNetwork">{{ formattedAccount }}</p>
                <p v-if="correctNetwork">{{ balance }} ETH</p>
                <button
                  v-if="!correctNetwork"
                  @click="switchNetwork"
                  class="switch-button"
                >
                  Switch to Sepolia
                </button>
                <button @click="disconnectMetaMask" class="disconnect-button">
                  Disconnect
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <!-- Mint Form -->
      <div class="mint-form">
        <div class="left-panel">
          <img :src="imageUrl" alt="NFT Image" class="nft-image" />
        </div>
        <div class="right-panel mint-box">
          <div class="mint-status">
            <h2>Public Mint is <span class="status-live">Coming Soon</span></h2>
            <div class="countdown">
              Whitelist Mint Starts in: {{ whitelistCountdown }}
            </div>
            <div class="countdown">
              Public Mint Starts in: {{ publicCountdown }}
            </div>
            <div class="mint-details">
              <p>
                Whitelist Mint Status:
                <span class="checkmark">Coming Soon</span>
              </p>
              <p>
                Public Mint Status: <span class="checkmark">Coming Soon</span>
              </p>
              <p><span class="label">2 MAX PER WALLET</span></p>
              <div class="price-remaining">
                <div>
                  <p>Price:</p>
                  <p><span class="price">0.068 ETH</span></p>
                </div>
                <div class="remaining">
                  <p>Minted:</p>
                  <div class="progress-container">
                    <div
                      class="progress-bar"
                      :style="{ width: mintedRatio + '%' }"
                    >
                      <span class="minted-amount"
                        >{{ minted }}/{{ total }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="quantity-container">
              <p>Quantity :</p>
              <button class="quantity-button" @click="decreaseQuantity">
                -
              </button>
              <div class="quantity-box">{{ quantity }}</div>
              <button class="quantity-button" @click="increaseQuantity">
                +
              </button>
            </div>
            <button class="mint-button" @click="mintToken">Mint Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { ethers } from "ethers";
import axios from "axios";
import contractABI from "./contractABI.json";
import config from "./config"; // Import the config file

export default {
  name: "MintPage",
  setup() {
    const sepoliaChainId = "0xaa36a7"; // Chain ID for Sepolia
    const account = ref(null);
    const balance = ref(null);
    const correctNetwork = ref(false);
    const showMetaMaskPopup = ref(false);
    const imageUrl = ref(require("@/assets/mint_page_machine.png"));
    const quantity = ref(2); // Start the quantity at 2
    const whitelistDate = new Date("2024-07-07T00:00:00");
    const publicDate = new Date("2024-07-08T00:00:00");
    const whitelistCountdown = ref("");
    const publicCountdown = ref("");
    const total = 20;
    const minted = ref(0); // Use ref for reactivity
    let mintedInterval;

    const connectMetaMask = async () => {
      if (window.ethereum) {
        try {
          console.log("Requesting MetaMask accounts...");
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          account.value = await signer.getAddress();
          await checkNetwork(); // Ensure the user is on the Sepolia network
          if (!correctNetwork.value) {
            showMetaMaskPopup.value = true;
          }
          console.log("Connected to MetaMask with account:", account.value);
        } catch (error) {
          console.error("Failed to connect to MetaMask:", error);
        }
      } else {
        console.error("MetaMask is not installed");
      }
    };

    const disconnectMetaMask = () => {
      account.value = null;
      balance.value = null;
      correctNetwork.value = false;
      showMetaMaskPopup.value = false;
      console.log("Disconnected from MetaMask");
    };

    const toggleMetaMaskPopup = async () => {
      if (!account.value) {
        await connectMetaMask();
      } else {
        await checkNetwork();
        showMetaMaskPopup.value = !showMetaMaskPopup.value;
      }
    };

    const switchNetwork = async () => {
      try {
        console.log("Switching to Sepolia network...");
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: sepoliaChainId }],
        });
        correctNetwork.value = true;
        await getBalance();
      } catch (switchError) {
        console.error("Failed to switch network:", switchError);
        if (switchError.code === 4902) {
          console.error("Sepolia network is not added to MetaMask");
        }
      }
    };

    const checkNetwork = async () => {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      correctNetwork.value = currentChainId === sepoliaChainId;
      if (correctNetwork.value) {
        await getBalance();
      }
    };

    const getBalance = async () => {
      if (account.value) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const balanceWei = await provider.getBalance(account.value);
        balance.value = ethers.formatEther(balanceWei);
      }
    };

    const handleClickOutside = (event) => {
      if (!showMetaMaskPopup.value) return;
      const popup = document.querySelector(".metamask-popup");
      if (popup && !popup.contains(event.target)) {
        closePopup();
      }
    };

    const closePopup = () => {
      showMetaMaskPopup.value = false;
    };

    const mintToken = async () => {
      if (!account.value) {
        console.log("MetaMask is not connected. Attempting to connect...");
        await connectMetaMask();
      }

      if (account.value) {
        try {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const currentChainId = await window.ethereum.request({
            method: "eth_chainId",
          });

          // Check if the current network is Sepolia
          if (currentChainId !== sepoliaChainId) {
            console.error("Please switch to the Sepolia network.");
            await switchNetwork();
            return;
          }

          const response = await axios.post(
            config.apiEndpoint,
            {
              address: account.value,
            },
            {
              headers: {
                "X-API-KEY": config.apiToken,
              },
            }
          );
          const merkleProof = response.data.proof;
          console.log("Merkle proof received:", merkleProof);

          // Minting function
          const signer = await provider.getSigner();
          const contract = new ethers.Contract(
            config.contractAddress,
            contractABI,
            signer
          );
          const mintAmount = quantity.value; // Use the quantity as the mint amount
          const tx = await contract.whitelistMint(mintAmount, merkleProof, {
            value: ethers.parseEther(
              (config.mintValue * mintAmount).toString()
            ),
          });
          console.log("Transaction:", tx);
        } catch (error) {
          console.error("Failed to mint token:", error);
        }
      } else {
        console.error("Failed to connect MetaMask. Cannot mint token.");
      }
    };

    const formattedAccount = computed(() => {
      return account.value
        ? `Connected: ${account.value.slice(0, 6)}...${account.value.slice(-4)}`
        : "";
    });

    const getMinted = async () => {
      if (window.ethereum) {
        try {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const contract = new ethers.Contract(
            config.contractAddress,
            contractABI,
            provider
          );
          const totalSupply = await contract.totalSupply();
          const newMintedValue = parseInt(totalSupply.toString());
          console.log(
            `Minted amount updated: ${minted.value} -> ${newMintedValue}`
          );
          minted.value = newMintedValue;
        } catch (error) {
          console.error("Failed to fetch total supply:", error);
        }
      } else {
        console.error("MetaMask is not installed");
      }
    };

    onMounted(async () => {
      document.addEventListener("click", handleClickOutside);
      await getMinted();
      mintedInterval = setInterval(async () => {
        console.log("Fetching updated minted amount...");
        await getMinted();
      }, 60000); // Trigger getMinted every minute
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
      clearInterval(mintedInterval);
    });

    const mintedRatio = computed(() => {
      return ((minted.value / total) * 100).toFixed(2); // Calculates the percentage of minted items
    });

    const updateCountdown = () => {
      const now = new Date();
      publicCountdown.value = getTimeRemaining(publicDate, now);
      whitelistCountdown.value = getTimeRemaining(whitelistDate, now);
      if (Date.parse(publicDate) <= Date.parse(now)) {
        publicCountdown.value = "Mint has ended";
      }
      if (Date.parse(whitelistDate) <= Date.parse(now)) {
        whitelistCountdown.value = "Mint has ended";
      }
    };

    const getTimeRemaining = (endtime, now) => {
      const total = Date.parse(endtime) - Date.parse(now);
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return `${days}d:${hours}h:${minutes}m:${seconds}s`;
    };

    onMounted(() => {
      updateCountdown();
      setInterval(updateCountdown, 1000);
    });

    return {
      account,
      balance,
      correctNetwork,
      imageUrl,
      quantity,
      whitelistCountdown,
      publicCountdown,
      total,
      minted,
      showMetaMaskPopup,
      connectMetaMask,
      disconnectMetaMask,
      toggleMetaMaskPopup,
      switchNetwork,
      closePopup,
      mintToken,
      formattedAccount,
      mintedRatio,
      increaseQuantity: () => {
        if (quantity.value < 2) {
          quantity.value++;
        }
      },
      decreaseQuantity: () => {
        if (quantity.value > 1) {
          quantity.value--;
        }
      },
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Grandstander");
.page {
  background: url("../assets/mint_page_bg.png") center center fixed; /* Keep your background image */
  background-size: cover;
}

/* Page Layout */
/* Page Layout */
.mint-page {
  width: 85%;
  margin-left: 7.5%;
  display: flex;
  flex-direction: column;
  padding: 10px; /* Added padding for better spacing on mobile */
  min-height: 100vh; /* Ensure the background covers the full viewport height */
  width: 100%; /* Ensure the background covers the full viewport width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  position: relative;
  z-index: 1;
}

/* Mint Form */
.mint-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 10px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  max-width: 70%;
  margin-left: 7.5%;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Added a semi-transparent background to distinguish from main background */
}

.left-panel,
.right-panel {
  padding: 20px;
  color: white;
}

.left-panel {
  flex: 1;
  max-width: 50%; /* Ensure the image takes up 50% of the mint form */
}

.right-panel {
  flex: 1;
  max-width: 50%; /* Ensure the right panel takes up 50% of the mint form */
}

.nft-image {
  width: 100%;
  height: auto;
  max-width: 100%;
}

/* Header Section */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  position: relative;
  height: 70px; /* Ensure consistent height */
}

.header-left-content {
  font-size: 2vw; /* Make GONAD smaller */
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; /* Center the navigation links */
}

nav a {
  margin: 0 15px; /* Adjusted margin for better spacing */
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5vw; /* Responsive font size */
}

.header-right-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.metamask-button {
  margin-left: 300px; /* Margin to separate from nav links */
  padding: 10px;
  font-size: 0.75vw; /* Responsive font size */
  display: flex;
  align-items: center;
  border-radius: 15px; /* Make edges curvy */
  background-color: #f5a623; /* Button background color */
}
.metamask-logo {
  width: 24px; /* Control the size of the MetaMask logo */
  height: 24px; /* Control the size of the MetaMask logo */
  margin-right: 10px;
}

/* MetaMask Popup */
.metamask-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  width: 300px;
  text-align: center;
}

.metamask-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.metamask-popup-logo {
  width: 50px;
  height: 50px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

.metamask-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.switch-button,
.disconnect-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.disconnect-button {
  background-color: #f56565;
}

.switch-button:hover,
.disconnect-button:hover {
  opacity: 0.9;
}

/* Mint Status */
.mint-status h2 {
  margin: 0;
  text-align: left;
  margin-bottom: 2vw;
  font-size: 1.5vw; /* Smaller font size */
}

.status-live {
  background-color: #4caf50; /* Green background color */
  color: white;
  padding: 2px 8px;
  border-radius: 5px;
}

.countdown {
  font-size: 1.2vw; /* Smaller font size */
  margin: 10px 0;
  text-align: left;
  color: #ffffff; /* White text color */
}

.mint-details {
  margin-top: 2vw;
}

.mint-details p {
  margin: 5px 0;
  line-height: 1.6;
  text-align: left;
  color: #ffffff; /* White text color */
  font-size: 1.2vw; /* Smaller font size */
}

.label {
  font-weight: bold;
  border: 2px solid #ffd700; /* Gold border */
  padding: 5px;
  border-radius: 5px;
}

.price-remaining {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.price,
.remaining {
  flex: 1;
  font-weight: bold;
  margin-right: 30px;
  font-size: 1.2vw; /* Smaller font size */
}

.price div,
.remaining div {
  text-align: center;
}

.progress-container {
  position: relative;
  width: 100%;
  background-color: #333333; /* Dark background color */
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 30px;
  background-color: #4caf50; /* Green background color */
  width: 0;
  line-height: 30px;
  color: white;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.minted-amount {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 12px;
  top: 0;
  left: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: flex-start; /* Align left */
}

.quantity-button {
  padding: 10px;
  margin: 0 5px;
  background-color: #333333; /* Dark background color */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2vw; /* Smaller font size */
  border-radius: 5px;
}

.quantity-box {
  min-width: 50px;
  text-align: center;
  font-size: 1.2vw; /* Smaller font size */
  background-color: #444444; /* Slightly lighter dark background color */
  color: white;
  padding: 10px;
  border-radius: 15px;
}

.mint-button {
  background-color: #4caf50; /* Green background color */
  color: white;
  border: none;
  border-radius: 15px;
  padding: 15px 30px;
  font-size: 1.5vw; /* Smaller font size */
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.mint-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.checkmark {
  color: limegreen;
  margin-left: 5px;
}

.price {
  color: #ffd700; /* Gold text color */
}

.remaining {
  color: #ff4500; /* Red text color */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    height: auto; /* Adjust height for mobile */
  }

  .header-left-content,
  .header-right-content {
    justify-content: center; /* Center align on mobile */
    margin-bottom: 10px; /* Margin between elements */
  }

  .header-right-content {
    flex-direction: column; /* Stack on top of each other on mobile */
  }

  .header-right-content nav {
    font-size: 1vw;
  }

  .mint-form {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    max-width: 100%; /* Full width on mobile */
  }

  .mint-status h2 {
    font-size: 4vw; /* Smaller font size */
  }

  .countdown,
  .mint-details p,
  .quantity-box {
    font-size: 3vw; /* Smaller font size */
  }

  .price,
  .remaining {
    font-size: 3vw; /* Smaller font size */
  }

  .quantity-button {
    font-size: 4vw; /* Smaller font size */
  }

  .mint-button {
    font-size: 2vw; /* Smaller font size */
    padding: 15px;
  }

  .metamask-logo {
    width: 10px; /* Adjust the width for mobile */
    height: 10px; /* Adjust the height for mobile */
  }

  .header-left-content {
    font-size: 6vw; /* Adjust GONAD size for mobile */
  }

  nav a {
    font-size: 3vw; /* Responsive font size for mobile */
    margin: 3px 1; /* Adjust margin for mobile */
  }

  .metamask-button {
    display: none;
  }
}
</style>
